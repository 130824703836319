<template>
  <div
    class="card briefcase"
  >
    <div class="card__header-line">
      <div class="card__first-line">
        <div class="fs15">
          {{ $t("rightPanel.briefcase.label") }}
        </div>
      </div>

      <div
        v-if="!loading"
        class="card__second-line"
      >
        <div class="balance">
          <span class="sum">
            <AuDecimalStatic
              :value="fullSum"
              :decimal="0"
              :addition="defaultTokenMnemonic"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && defaultTokenMnemonic"
      class="card__third-line"
    >
      <div
        v-if="!balance"
      >
        <AuButton
          type="primary"
          center
          width="100%"
          @click="$router.push('/briefcase/buy-form')"
        >
          {{ $t("briefcase.buy") }} {{ defaultTokenEnv }}
        </AuButton>
      </div>
      <Apexchart
        v-if="!chartLoading && balance"
        class="chard"
        :options="options"
        :series="mappedSeries"
        height="400px"
        width="170px"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      currencySup: new CurrencySupport(),
      loading: false,
      fullSum: 0,
      balance: 0,
      series: [],
      mappedSeries: [],
      width: null,
      chartLoading: false
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      tokensDao: "tokensDao",
      tokensUtility: "tokensUtility",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      exchangeBalances: "exchangeBalances",
      defaultTokenEnv: "defaultToken/defaultTokenEnv",
      checkProfile: "profile/checkProfile"
    }),

    isLegentHeight() {
      if (this.series.length > 10) {
        return 140;
      }
      return this.series.length ? (Math.ceil(this.series.length / 2) * 2) * 10 : 0;
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    colorLabel() {
      return this.isLightTheme ? Array(9).fill("#080808") : Array(9).fill("#fff");
    },

    nonAsset() {
      return [...this.tokensFiat, ...this.tokensGold,
        this.tokensDao, ...this.tokensUtility];
    },

    labels() {
      let resp = [];
      this.series.forEach(series => {
        const owner = _.find(this.auOwners, owner => owner.auToken.id == series.id);

        if (owner.auToken.mnemonicEng == this.defaultTokenEnv) {
          resp.push(owner.auToken.mnemonicEng);
        }
        else {
          resp.push(`<span class="legend">${this.defaultTokenEnv}: <b>${ Number(this.getPrice(owner)).toLocaleString("ru") }</b><br/></span>${owner.auToken.mnemonicEng}`);
        }
      });

      return resp;
    },

    options: {
      get: function () {
        return {
          labels: this.labels,
          chart: {
            type: "donut"
          },
          pie: {
            expandOnClick: false,
            donut: {
              background: "transparent",
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              }
            }
          },
          colors: [
            "#299667",
            "#9720F6",
            "#E2A64D",
            "#489EE1",
            "#294896",
            "#35d5ec",
            "#E2684D",
            "#504DE2",
            "#C3B3D0"
          ],
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: true,
            y: {
              formatter(value) {
                return value.toLocaleString("ru");
              }
            }
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            height: this.isLegentHeight,
            width: 170,
            fontSize: "10px",
            fontFamily: "Suisse Intl",
            labels: {
              colors: this.colorLabel
            },
            markers: {
              width: 12,
              height: 8
            }
          }
        };
      },
      set: function (newValue) {
        return newValue;
      }
    }
  },

  watch: {
    auOwners: {
      immediate: true,
      handler() {
        this.checkBalance(this.auOwners);
      }
    },
    defaultTokenMnemonic: {
      immediate: true,
      handler() {
        this.checkBalance(this.auOwners);
      }
    },
    checkProfile: {
      immediate: true,
      async handler() {
        await this.getAuOwners();
      }
    }
  },

  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },

  methods: {
    ...mapActions({
      getOwnerPage: "getOwnerPage",
      getAuOwners: "getAuOwners",
      getWithdrawalRequests: "withdrawal/getWithdrawalRequests"
    }),

    resizeHandler() {
      this.chartLoading = true;
      this.$nextTick(() => {
        this.chartLoading = false;
      });
    },
    changeChart(chartContext, _config) {
      this.$nextTick(() => {
        chartContext.pie.centerX = 75;
      });
    },

    getPrice(owner) {
      let price = this.currencySup.getCurrency(owner.auToken.id, this.defaultTokenMnemonic);
      price = BigNumber(price).multipliedBy(owner.amount);
      price = price.dividedBy(BigNumber(10).pow(owner.auToken.decimals)).toNumber();

      // Т.к. у нас нет спотовых кошельков. Если будут, то ниже раскомментировать, а выше закоментировать
      // let spotBalance = _.get(_.find(this.exchangeBalances, { token: `/api/au_tokens/${owner.auToken.id}` }), "amount") || "0";
      // price = price.plus(spotBalance).dividedBy(BigNumber(10).pow(owner.auToken.decimals)).toNumber();
      return price = Number.isNaN(price) ? 0 : price;
    },

    checkBalance(value) {
      this.series = [];
      this.mappedSeries = [];

      _.forEach(value, owner => {
        let price = this.getPrice(owner);

        const isFloor = Math.floor(price) == price;
        const finalPrice = isFloor ? Math.floor(price) : price;

        this.series.push({ id: owner.auToken.id, price: finalPrice });
        this.mappedSeries.push(finalPrice);
      });

      this.balance = _.sum(this.mappedSeries);
      this.fullSum = _.sum(this.mappedSeries);
    }
  }
};
</script>


<style lang="scss">
.briefcase {
  .apexcharts-legend {
    height: 100px;
    display: grid;
    grid-template-columns: 50% auto;
  }
  .apexcharts-legend-text {
    .legend {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.balance {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  .sum {
    text-align: center;
    line-height: 24px;
    margin-top: -5px;
  }

  color: #5ac396;

  @media screen and (max-width: 1200px) {
    font-size: 18px;
  }
}

.loader-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;

  &__first-line {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: var(--text-color);
  }

  &__third-line {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.briefcase{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
</style>
