<template>
  <span />
</template>

<script>
export default {
  name: "TermsVote"
};
</script>

<style scoped>

</style>