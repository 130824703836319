<template>
  <div class="transactions-list">
    <div
      v-for="(group, groupIndex) in transactions"
      :key="groupIndex"
      class="transactions-group"
    >
      <div class="period">
        {{ group.date }}
      </div>

      <div class="transactions">
        <LastTransaction
          v-for="(transaction, index) in group.transactions"
          :key="index"

          :name="transaction.name"
          :transaction-type="transaction.type"
          :amount="transaction.amount"
          :is-refill="transaction.isRefill"
          :invesrt-signs="invesrtSigns"
          :card-type="transaction.cardType"
          :decimals="transaction.decimals"
          :mnemonic="transaction.mnemonic"
          :is-confirmed="transaction.isConfirmed"
          :hash="transaction.hash"
          :address-eth="transaction.addressEth"
          :address-from="transaction.addressFrom"
          :address-to="transaction.addressTo"
          :icon="transaction.icon"
          :is-owner-transaction="transaction.isOwnerTransaction"
          :project-id="transaction.projectId"
          :project-name="transaction.projectName"
          :is-voting="transaction.isVoting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LastTransaction from "./LastTransaction.vue";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    LastTransaction
  },

  props: {
    transactions: {
      type: Array,
      default: () => []
    },
    invesrtSigns: {
      type: Boolean,
      default: false
    }
  }
};

</script>

<style scoped lang="scss">
.period {
  margin: 20px 0px;
  font-size: 12px;
  color: var(--color-60);
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-footer {
    padding-bottom: 10px;
  }

  &-info {
    display: flex;
    border-bottom: 1px solid var(--color-70);
    padding: 12px 0px;

    @media screen and (max-width:576px) {
      flex-direction: column;
    }

    &__chart {
      display: flex;
      align-items: center;
      margin-left: -17px;
      margin-right: 40px;

      @media screen and (max-width:576px) {
        justify-content: space-between;
      }
    }

    &__cards {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 50px 0px;
    }
  }
}
</style>
