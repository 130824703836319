<template>
  <div class="card vote-main">
    <div class="title">
      {{ $t("topVotes.title") }}
    </div>
    <ProgressLoader
      v-if="loadAndCalc"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <Apexchart
        :options="options"
        :series="topVoting"
        @click="goToProject"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TopVotes",
  components: { ProgressLoader },
  data() {
    return {
      loadAndCalc: false,
      topVoting: []
    };
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    options() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          formatter: (v, _) => {
            return `${v}%`;
          }
        },
        xaxis: {
          type: "category",
          max: 100
        },
        yaxis: {
          labels: {
            style: {
              colors: this.isLightTheme ? "black" : "white",
              cssClass: "votes-clickable"
            }
          }
        }
      };
    }
  },
  async created() {
    await this.calcTop();
  },
  methods: {
    ...mapActions({
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination"
    }),

    goToProject(e) {
      const vote = _.find(this.topVoting[0]?.data, item => item?.x === e.target.innerHTML);
      if (vote) {
        this.$router.push({ name: "project card", params: { id: vote.id } });
      }
    },

    getY(project, oneHundredPercent) {
      const value = BigNumber(project.currentCap).dividedBy(BigNumber(10).pow(project.tokenQuote.decimals));
      return value.multipliedBy(100).dividedBy(oneHundredPercent).toFixed(2);
    },

    async calcTop() {
      this.loadAndCalc = true;
      try {
        const resp = await this.getAllAuCapitalsPagination({
          page: 1,
          itemsPerPage: 100,
          projectType: "voting"
        });
        // сортировка пока на фронте, но как будут фильтры бэка
        // все это будет переделано
        const sorted = resp.data.sort((a, b) => {
          const bigA = BigNumber(a.currentCap).dividedBy(BigNumber(10).pow(a.tokenQuote.decimals));
          const bigB = BigNumber(b.currentCap).dividedBy(BigNumber(10).pow(b.tokenQuote.decimals));
          return bigB.minus(bigA).toNumber();
        });
        const max = BigNumber(sorted[0].currentCap).dividedBy(BigNumber(10).pow(sorted[0].tokenQuote.decimals));
        sorted.length = sorted.length > 10 ? 10 : sorted.length;
        this.topVoting = [{
          data: _.map(sorted, item => ({
            x: item.tokenBase.nameEng,
            y: this.getY(item, max),
            id: item.id
          }))
        }];
      }
      catch (e) {
        console.error(e);
      }
      this.loadAndCalc = false;
    }
  }
};
</script>

<style lang="scss">
.votes-clickable {
  cursor: pointer;
}
</style>

<style scoped lang="scss">

.vote-main {
  padding: 20px;

  .title {
    font-size: 24px;
  }
}
</style>